<template>
  <div class="add-banner">
    <div class="titles">{{ title }}</div>
    <div class="cell">
      <el-form
        size="mini"
        style="width: 800px"
        ref="form"
        :rules="rules"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="公告标题" prop="name">
          <el-input v-model="form.title"></el-input>
        </el-form-item>

        <el-form-item label="描述">
          <el-input v-model="form.description"></el-input>
        </el-form-item>

        <el-form-item label="排序">
          <el-input v-model="form.weigh"></el-input>
        </el-form-item>
        <el-form-item label="内容">
          <div style="border: 1px solid #ccc; width: 100%; touch-action: none">
            <Toolbar
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 300px; border-top: 1px solid #ccc"
              v-model="form.content"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确认</el-button>
          <el-button @click="handleBack" plain>取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { noticeAdd, noticeEdit } from "@/api/admin.js";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  components: {
    Editor,
    Toolbar,
  },
  data() {
    return {
      form: {
        id: 0,
        title: "",
        keyword: "",
        description: "",
        weigh: "",
        status: 1,
        content: "",
      },
      options: [],
      props: {
        value: "category_id",
        label: "name",
        checkStrictly: true,
        emitPath: false,
      },
      rules: {
        title: [{ required: true, message: "请输入轮播名称", trigger: "blur" }],
      },
      imgList: [],
      urlList: [],
      ids: [],
      title: "新增",

      dialogImageUrl: "",
      dialogVisible: false,
      editor: null,
      toolbarConfig: {
        excludeKeys: ["group-image", "insertVideo", "insertTable", "codeBlock","emotion","todo"],
      }, //富文本工具栏配置
      editorConfig: {
        // 富文本菜单栏配置
        placeholder: "请输入内容...",
        MENU_CONF: {
          "z-index": 99,
        },
      },
      mode: "simple", // or 'simple'
    };
  },
  created() {
    if (sessionStorage.ADMIN_NOTICE_INFO) {
      this.title = "编辑";
      this.form = JSON.parse(sessionStorage.ADMIN_NOTICE_INFO);
      //   this.editor.
    }
  },
  mounted() {
    // this.onCreated()
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated(editor) {

      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    onSubmit() {
      this.form.keyword = this.form.title;
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id == 0) {
            noticeAdd(this.form).then((res) => {
              if (res.code == 1) {
                this.$message.success("添加公告成功");
                this.handleBack();
              }
            });
          } else {
            noticeEdit(this.form).then((res) => {
              if (res.code == 1) {
                this.$message.success("编辑公告成功");
                this.handleBack();
              }
            });
          }
        }
      });
    },
    handleBack() {
      // this.$router.replace("adminBanner");
      this.$router.back();
    },
    handleChange() {},

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less">
.add-banner {
  .titles {
    font-size: 18px;
    padding-bottom: 20px;
  }
  .cell {
    background: #fff;
    padding: 20px;
  }
  a {
    color: blue;
    text-decoration: underline;
  }
  ul{
    list-style: disc;
  }
  ol {
    list-style: decimal;
  }
}
.el-form-item__label {
  font-size: 12px;
}
.el-radio__label {
  font-size: 12px;
}
.w-e-full-screen-container {
  z-index: 99;
}
</style>